<template>
    <div class="businessPoints">
        <el-card shadow="never" style="margin-top: 8px">
            <el-form
                @submit.native.prevent
                :inline="true"
                style="text-align: right"
                v-model="form"
                size="small"
                label-width="80px"
            >
                <el-row>
                    <el-col :span="6">
                        <el-form-item label="兑换编号">
                            <el-input placeholder="兑换编号" v-model="form.code" />
                        </el-form-item>
                    </el-col>
                    <el-col :span="6">
                        <el-form-item label="分销员">
                            <el-input placeholder="分销员姓名/手机号" v-model="form.distributionMobile" />
                        </el-form-item>
                    </el-col>
                    <el-col :span="6">
                        <el-form-item label="兑换机构">
                            <el-select placeholder="机构名称" v-model="form.deptCode" filterable>
                                <el-option label="请选择" value="" />
                                <el-option
                                    v-for="dept in depts"
                                    :label="dept.name"
                                    :value="dept.code"
                                    :key="dept.code"
                                />
                            </el-select>
                        </el-form-item>
                    </el-col>
                    <el-col :span="6">
                        <el-form-item label="兑换类型">
                            <el-select placeholder="兑换类型" v-model="form.exchangeType">
                                <el-option label="请选择" value="" />
                                <el-option label="商品" value="1" />
                                <el-option label="现金" value="2" />
                            </el-select>
                        </el-form-item>
                    </el-col>
                </el-row>
                <el-row style="margin-top: 16px">
                    <el-col :span="6">
                        <el-form-item label="兑换商品">
                            <el-input placeholder="兑换商品名称" v-model="form.goodsName" />
                        </el-form-item>
                    </el-col>
                    <el-col :span="6">
                        <ef-start-date v-model="form.startTime" />
                    </el-col>
                    <el-col :span="6">
                        <ef-end-date v-model="form.endTime" />
                    </el-col>
                </el-row>
            </el-form>
        </el-card>
        <el-card shadow="never" style="margin-top: 8px; text-align: left">
            <el-button type="primary" @click="handleQuery" size="small">查询 </el-button>
            <el-button
                type="primary"
                @click="handleCreate"
                v-if="hasPrivilege('menu.mall.manage.businessPoints.create')"
                size="small"
                >新建
            </el-button>
            <el-button
                type="primary"
                @click="handleExport"
                size="small"
                v-if="hasPrivilege('menu.mall.manage.businessPoints.export')"
                >导出
            </el-button>
            <span style="float: right">
                <el-button size="mini" @click="showCheckTableShowColumnDialog">表头</el-button>
                <el-button size="mini" @click="handlePrint">打印</el-button>
            </span>
        </el-card>
        <check-table-show-column-dialog ref="checkTableShowColumnDialog" @checkTableShowColumn="setShowTableColumns" />
        <el-card shadow="never" style="margin-top: 8px">
            <el-table
                ref="table"
                id="printMe"
                border
                stripe
                style="width: 100%"
                :data="tableData"
                size="mini"
                :highlight-current-row="true"
                max-height="440"
            >
                <el-table-column label="序号" width="60" type="index" fixed="left" />
                <el-table-column label="兑换编号" width="100" prop="code" />
                <el-table-column label="分销员姓名" width="100" prop="distributionNickName" />
                <el-table-column label="分销员手机号" width="100" prop="distributionMobile" />
                <el-table-column label="所属机构" width="180" prop="deptName" />
                <el-table-column label="扣除商分" width="100" prop="exchangeScore" />
                <el-table-column label="兑换类型" width="100" prop="exchangeType" key="exchangeType">
                    <template slot-scope="scope">
                        <span>{{ scope.row.exchangeType | exchangeType }}</span>
                    </template>
                </el-table-column>
                <el-table-column label="兑换金额" width="100" prop="exchangeMoney" key="exchangeMoney">
                    <template slot-scope="scope">
                        <span>{{ scope.row.exchangeMoney | money }}</span>
                    </template>
                </el-table-column>
                <el-table-column label="兑换商品" width="120" prop="goodsName" />
                <el-table-column label="兑换机构" width="120" prop="changeDeptName" />
                <el-table-column label="商品数量" width="120" prop="goodsCount" />
                <el-table-column label="审核人" width="120" prop="creator" />
                <el-table-column label="兑换时间" prop="createTime" />
                <el-table-column label="操作" min-width="90" header-align="center" key="operate" prop="operate">
                    <template slot-scope="scope">
                        <el-button
                            size="mini"
                            @click="rowDetail(scope.row)"
                            v-if="hasPrivilege('menu.mall.manage.businessPoints.open')"
                            >查看
                        </el-button>
                    </template>
                </el-table-column>
            </el-table>
            <el-pagination
                :page-sizes="page.pageSizes"
                :page-size="form.limit"
                :layout="page.PageStyle"
                :total="page.total"
                @size-change="handleSizeChange"
                @current-change="handleCurrentChange"
                @prev-click="handlePrevClick"
                @next-click="handleNextClick"
            />
        </el-card>
    </div>
</template>

<script>
import Util from '../../../js/Util';
import MoneyUtils from '../../../js/MoneyUtils';
import UrlUtils from '../../../js/UrlUtils';
import EfStartDate from 'components/EfStartDate';
import EfEndDate from 'components/EfEndDate';
import { DeptTypeEnum } from 'js/DeptCommon';
import CheckTableShowColumn from 'components/mixins/CheckTableShowColumn';
import CheckTableShowColumnDialog from 'components/CheckTableShowColumnDialog';

export default {
    name: 'CashRefund',
    mixins: [CheckTableShowColumn],
    components: { EfEndDate, EfStartDate, CheckTableShowColumnDialog },
    data() {
        return {
            form: {
                code: '',
                deptCode: null,
                startTime: Util.formatDateOfLastMonth(),
                endTime: Util.nowFormatDate(),
                exchangeType: null,
                distributionMobile: '',
                goodsName: '',
                page: 1,
                limit: Util.Limit,
            },
            depts: [],
            current: {
                typeIdx: 3,
                deptCode: '',
            },
            page: {
                total: 0,
                pageSizes: Util.PageSizes,
                PageStyle: Util.PageStyle,
            },
            tableData: [],
            url: {
                page: '/distribution/exchange/page',
            },
        };
    },
    mounted() {
        this.$efApi.deptApi.managedDeptsByDeptType(DeptTypeEnum.STORE).then((rst) => {
            this.depts = rst;
            this.handleQuery();
        });
    },
    activated() {
        this.executeQueryIfNeedReload(this.handleQuery);
    },
    filters: {
        settleStatus(settleStatus) {
            if (typeof settleStatus == 'undefined') {
                return '';
            }
            if (settleStatus == '0') {
                return '待结算';
            }
            if (settleStatus == '1') {
                return '已结算';
            }
        },
        exchangeType(exchangeType) {
            if (typeof exchangeType == 'undefined') {
                return '';
            }
            if (exchangeType == '1') {
                return '商品';
            }
            if (exchangeType == '2') {
                return '现金';
            }
        },
        money(money) {
            if (typeof money != 'undefined') {
                return MoneyUtils.formatMoney(money, 2);
            }
            return '';
        },
    },
    methods: {
        handleCreate() {
            Util.nameJump(this, 'menu.mall.manage.businessPoints.create', ['电商管理', '商分管理', '新建兑换单']);
        },
        handlePrint() {
            this.printHTML('printMe', this.$route.meta.title);
        },
        handleQuery() {
            const _this = this;
            const _params = { params: _this.form };
            Util.queryTable(_this, _this.url.page, _params);
        },
        rowDetail(row) {
            Util.nameJump(this, 'menu.mall.manage.businessPoints.detail', ['电商管理', '商分管理', '查看兑换单'], {
                form: row,
                code: row.code,
            });
        },
        handleExport() {
            UrlUtils.Export(this, '收银退货', '/distribution/exchange/export', this.form);
        },
    },
};
</script>

<style scoped>
.CashRefund .el-form-item {
    margin-bottom: 0;
}
</style>
